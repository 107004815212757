<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-center justify-content-center px-0"
    >
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center table-card-row"
        no-gutters
      >
        <b-card
          no-body
          class="settings-container-card d-flex flex-row align-items-start justify-content-start w-100"
        >
          <b-row
            class="d-flex flex-row align-items-stretch justify-content-center table-card-row"
            no-gutters
          >
            <b-col cols="12" md="6">
              <b-card
                class="left-container-card d-flex flex-row align-items-start justify-content-start border-0 w-100"
              >
                <b-row
                  class="d-flex flex-row align-items-start justify-content-start w-100 card-title"
                  no-gutters
                  >Account details</b-row
                >
                <b-row
                  class="d-flex flex-row align-items-start justify-content-start w-100"
                  no-gutters
                >
                  <form
                    id="admin-form"
                    class="d-flex flex-column align-items-start justify-content-start w-100"
                  >
                    <!-- full row input (Name) -->
                    <b-row
                      class="d-flex flex-row align-items-start justify-content-start w-100 mt-3"
                      no-gutters
                    >
                      <b-col cols="3">
                        <span class="left-text">Name</span>
                      </b-col>
                      <b-col cols="9">
                        <span class="right-text">{{
                          this.accountData.userName
                        }}</span>
                      </b-col>
                    </b-row>

                    <!-- full row input (Email) -->
                    <b-row
                      class="d-flex flex-row align-items-start justify-content-start w-100 mt-3"
                      no-gutters
                    >
                      <b-col cols="3">
                        <span class="left-text">Email</span>
                      </b-col>
                      <b-col cols="9">
                        <span class="right-text">{{
                          this.accountData.email
                        }}</span>
                      </b-col>
                    </b-row>

                    <!-- full row input (Joined Date) -->
                    <b-row
                      class="d-flex flex-row align-items-start justify-content-start w-100 mt-3"
                      no-gutters
                    >
                      <b-col cols="3">
                        <span class="left-text">Joined Date</span>
                      </b-col>
                      <b-col cols="9">
                        <span class="right-text">{{
                          formatDate(this.accountData.joinedDate)
                        }}</span>
                      </b-col>
                    </b-row>

                    <!-- full row input (Type) -->
                    <b-row
                      class="d-flex flex-row align-items-start justify-content-start w-100 mt-3"
                      no-gutters
                    >
                      <b-col cols="3">
                        <span class="left-text">Type</span>
                      </b-col>
                      <b-col cols="9">
                        <span class="type-right-text">{{
                          this.accountData.type
                        }}</span>
                      </b-col>
                    </b-row>
                  </form>
                </b-row>
              </b-card>
            </b-col>

            <b-col cols="12" md="6">
              <b-card
                class="right-container-card d-flex flex-row align-items-start justify-content-start border-0 w-100"
              >
                <b-row
                  class="d-flex flex-row align-items-start justify-content-start w-100 card-title"
                  no-gutters
                  >Password settings
                </b-row>
                <b-row
                  class="d-flex flex-row align-items-start justify-content-start w-100"
                  no-gutters
                >
                  <form
                    id="admin-form"
                    class="d-flex flex-column align-items-start justify-content-start w-100"
                  >
                    <!-- full row input (Old Password) -->
                    <b-row
                      class="d-flex flex-row align-items-start justify-content-start w-100 mt-3"
                      no-gutters
                    >
                      <b-col cols="12">
                        <FormInputGroup
                          groupId="password-group"
                          id="password-group-input"
                          v-model="oldPassword"
                          :state="oldPasswordError.status"
                          :invalidFeedback="`${oldPasswordError.message}`"
                          label="Old Password"
                          placeholder="Minimum 6 characters"
                          isRequired
                          :whiteBG="false"
                          :type="showOldPassword ? 'text' : 'password'"
                          form="pwd-reset-form"
                          isAppend
                        >
                          <template v-slot:append>
                            <b-button
                              class="px-3 px-md-4 rounded-0"
                              variant="transparent"
                              @click="showOldPassword = !showOldPassword"
                            >
                              <b-icon
                                :icon="showOldPassword ? 'eye' : 'eye-slash'"
                                aria-label="Help"
                              ></b-icon>
                            </b-button>
                          </template>
                        </FormInputGroup>
                      </b-col>
                    </b-row>

                    <!-- full row input (New Password) -->
                    <b-row
                      class="d-flex flex-row align-items-start justify-content-start w-100 mt-2"
                      no-gutters
                    >
                      <b-col cols="12">
                        <FormInputGroup
                          groupId="password-group"
                          id="password-group-input"
                          v-model="newPassword"
                          :state="newPasswordError.status"
                          :invalidFeedback="`${newPasswordError.message}`"
                          label="New Password"
                          placeholder="Minimum 6 characters"
                          isRequired
                          :whiteBG="false"
                          :type="showNewPassword ? 'text' : 'password'"
                          form="pwd-reset-form"
                          isAppend
                        >
                          <template v-slot:append>
                            <b-button
                              class="px-3 px-md-4 rounded-0"
                              variant="transparent"
                              @click="showNewPassword = !showNewPassword"
                            >
                              <b-icon
                                :icon="showNewPassword ? 'eye' : 'eye-slash'"
                                aria-label="Help"
                              ></b-icon>
                            </b-button>
                          </template>
                        </FormInputGroup>
                      </b-col>
                    </b-row>

                    <!-- full row input (Confirm Password) -->
                    <b-row
                      class="d-flex flex-row align-items-start justify-content-start w-100 mt-2"
                      no-gutters
                    >
                      <b-col cols="12">
                        <FormInputGroup
                          groupId="password-group"
                          id="password-group-input"
                          v-model="confirmPassword"
                          :state="confirmPasswordError.status"
                          :invalidFeedback="`${confirmPasswordError.message}`"
                          label="Confirm New Password"
                          placeholder="Minimum 6 characters"
                          isRequired
                          :whiteBG="false"
                          :type="showConfirmPassword ? 'text' : 'password'"
                          form="login-form"
                          isAppend
                        >
                          <template v-slot:append>
                            <b-button
                              class="px-3 px-md-4 rounded-0"
                              variant="transparent"
                              @click="
                                showConfirmPassword = !showConfirmPassword
                              "
                            >
                              <b-icon
                                :icon="
                                  showConfirmPassword ? 'eye' : 'eye-slash'
                                "
                                aria-label="Help"
                              ></b-icon>
                            </b-button>
                          </template>
                        </FormInputGroup>
                      </b-col>
                    </b-row>

                    <!-- form action -->
                    <b-row
                      class="d-flex flex-row align-items-center justify-content-center w-100"
                      no-gutters
                    >
                      <!-- admin create button -->
                      <b-col
                        class="d-flex flex-column align-items-center justify-content-center px-0 py-2"
                        cols="12"
                        md="10"
                      >
                        <FormButton
                          isBlock
                          isPill
                          variant="main-green"
                          class="font-weight-normal text-secondary mt-3 reset-button"
                          type="submit"
                          @click.native="resetPwdFn"
                          ><span class="text-white">Reset Password</span>
                        </FormButton>
                      </b-col>
                    </b-row>
                  </form>
                </b-row>
              </b-card>
            </b-col>
            <div>
              <b-img
                src="@/assets/BlackVault.png"
                class="Figalogo-img"
                alt="Figalogo"
              ></b-img>
              <p
                class="text-center"
                style="font-size: 14px; font-weight: 600; color: #8793a3"
              >
                V0.0.16
              </p>
            </div>
          </b-row>
        </b-card>
      </b-row>
    </b-container>
  </HomeLayout>
</template>

<script>
import HomeLayout from "@/layout/HomeLayout";
// import FormInput from "@/components/Form/FormInput";
import FormInputGroup from "@/components/Form/FormInputGroup";
import FormButton from "@/components/Form/FormButton";

// services
import { GetAccountDetails, ResetPassword } from "@/services/account.service";

export default {
  name: "Account Settings",
  components: {
    HomeLayout,
    //FormInput,
    FormInputGroup,
    FormButton,
  },
  data() {
    return {
      isReset: false,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      oldPasswordError: {
        status: null,
        message: "",
      },
      newPasswordError: {
        status: null,
        message: "",
      },
      confirmPasswordError: {
        status: null,
        message: "",
      },
      accountData: {
        userName: "",
        email: "",
        joinedDate: "",
        type: "",
      },
    };
  },
  watch: {
    oldPassword(val) {
      if (!this.isReset) {
        if (val) {
          if (val.length < 6) {
            this.oldPasswordError.status = false;
            this.oldPasswordError.message =
              "A minimum length of 6 characters needed";
          } else {
            this.oldPasswordError.status = true;
          }
        } else {
          this.oldPasswordError.status = false;
          this.oldPasswordError.message = "Please fill in your old password";
        }
      }
    },
    newPassword(val) {
      if (!this.isReset) {
        if (val) {
          if (val.length < 6) {
            this.newPasswordError.status = false;
            this.newPasswordError.message =
              "A minimum length of 6 characters needed";
          } else {
            this.newPasswordError.status = true;
          }
        } else {
          this.newPasswordError.status = false;
          this.newPasswordError.message = "Please fill in your new password";
        }
      }
    },
    confirmPassword(val) {
      if (!this.isReset) {
        if (val) {
          if (val.length < 6) {
            this.confirmPasswordError.status = false;
            this.confirmNewPasswordError.message =
              "A minimum length of 6 characters needed";
          } else {
            if (val == this.newPassword) {
              this.confirmPasswordError.status = true;
            } else {
              this.confirmPasswordError.status = false;
              this.confirmPasswordError.message =
                "Password should match the above one";
            }
          }
        } else {
          this.confirmPasswordError.status = false;
          this.confirmPasswordError.message =
            "Please fill in your new password again";
        }
      }
    },
  },
  async mounted() {
    await this.initFn();
  },
  methods: {
    async initFn() {
      try {
        let { data } = await GetAccountDetails();
        const date = data.joinedDate.split("T")[0];

        this.accountData = {
          userName: data.name,
          email: data.email,
          joinedDate: date,
          type: "Administrator",
        };
      } catch (error) {
        console.log(error);
      }
    },
    async resetPwdFn() {
      if (
        this.oldPassword &&
        this.newPassword &&
        this.confirmPassword &&
        this.newPassword == this.confirmPassword
      ) {
        let payload = {
          old_password: this.oldPassword,
          new_password: this.newPassword,
        };
        this.isLoading = true;
        console.log("payload", payload);
        try {
          let { data, status } = await ResetPassword(payload);
          console.log("reset data", data);
          if (status == 200) {
            this.$bvToast.toast("Success", {
              title: "Password Reset Successfull",
              toaster: "b-toaster-bottom-right",
              variant: "success",

              solid: true,
            });
            this.resetFn();
          } else {
            this.$bvToast.toast("Something went wrong", {
              title: "Reset password failed",
              toaster: "b-toaster-bottom-right",
              variant: "danger",

              solid: true,
            });
          }
        } catch (error) {
          this.$bvToast.toast("Old password is incorrect", {
            title: "Old password is incorrect",
            toaster: "b-toaster-bottom-right",
            variant: "danger",

            solid: true,
          });
        } finally {
          this.isLoading = false;
        }
      } else {
        this.errorFn();
        alert("Error");
      }
    },
    errorFn() {
      if (!this.oldPassword) {
        this.oldPasswordError.status = false;
        this.oldPasswordError.message = "Please fill in your old password";
      }
      if (!this.newPassword) {
        this.newPasswordError.status = false;
        this.newPasswordError.message = "Please fill in your new password";
      }
      if (!this.confirmNewPassword) {
        this.confirmPasswordError.status = false;
        this.confirmPasswordError.message =
          "Please fill in your new password again";
      }
      if (this.confirmPassword != this.newPassword) {
        this.confirmPasswordError.status = false;
        this.confirmPasswordError.message =
          "Password should match the above one";
      }
    },
    resetFn() {
      this.isReset = true;
      this.oldPassword = null;
      this.newPassword = null;
      this.confirmPassword = null;

      setTimeout(() => {
        this.oldPasswordError.status = null;
        this.newPasswordError.status = null;
        this.confirmPasswordError.status = null;
      }, 600);
    },
    formatDate(val) {
      if (val) {
        const dates = val.split("-");
        return `${dates[2]}/${dates[1]}/${dates[0]}`;
      }
    },
  },
};
</script>

<style scoped>
/* table card */

.table-card-row {
  height: 81vh;
  width: 90vw;
}
.left-container-card {
  background-color: var(--white);

  position: relative;
  /* width: calc(100% - 100px); */
  /* width: 100%; */
  min-height: 65vh;
  padding: 10px;
  left: 0;
  right: 0;

  /* right: 40px; */
  /* bottom: 45px; */

  border-radius: 16px 0px 0px 0px;
}
.right-container-card {
  background-color: var(--white);
  position: relative;
  /* width: calc(100% - 100px); */
  /* width: 100%; */
  box-shadow: 3px 0px rgba(135, 147, 163, 0.25) inset;
  min-height: 65vh;
  padding: 10px;
  left: 0;
  right: 0;

  /* right: 40px; */
  /* bottom: 45px; */

  border-radius: 0px 16px 0px 0px;
}
.card-title {
  font-family: "Lato";
  font-weight: 400;
  font-size: 16px;
  color: #333333;
}
.reset-button {
  width: 162px;
  height: 50px;
  background-color: #0b9b7d;
  border-radius: 30px;
}
.left-text {
  font-family: "Lato";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #9fa2b4;
}
.right-text {
  font-family: "Lato";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}
.type-right-text {
  font-family: "Lato";
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
  color: #b22234;
  background: #f6f8fa;
  border-radius: 5px;
  padding: 4px 8px;
}
.table-card-row .Figalogo-img {
  align-items: center;
  width: 180px;
  height: 60px;
}
.settings-container-card {
  background-color: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  position: relative;
  /* width: calc(100% - 100px); */
  /* width: 100%; */
  min-height: 80vh;

  /* right: 40px; */
  /* bottom: 45px; */
  border: 1px solid rgba(135, 147, 163, 0.25);
  border-radius: 16px 16px 16px 16px;
}
.verticalLine {
  border-left: thick solid #ff0000;
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .settings-container-card {
    min-height: auto; /* Remove the minimum height */
    border-radius: 16px; /* Restore the border-radius */
  }

  .table-card-row {
    flex-direction: column; /* Display rows in a column */
    height: auto; /* Remove fixed height */
    width: 100%; /* Full width */
  }

  .left-container-card {
    box-shadow: none; /* Remove box-shadow on small devices */
    border-radius: 16px; /* Restore the border-radius */
    min-height: 30vh;
  }
  .right-container-card {
    box-shadow: none; /* Remove box-shadow on small devices */
    border-radius: 16px; /* Restore the border-radius */
    min-height: 40vh;
  }
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .settings-container-card {
    min-height: auto; /* Remove the minimum height */
    border-radius: 16px; /* Restore the border-radius */
  }

  .table-card-row {
    flex-direction: column; /* Display rows in a column */
    height: auto; /* Remove fixed height */
    width: 100%; /* Full width */
  }

  .left-container-card {
    box-shadow: none; /* Remove box-shadow on small devices */
    border-radius: 16px; /* Restore the border-radius */
    min-height: 30vh;
  }

  .right-container-card {
    box-shadow: none; /* Remove box-shadow on small devices */
    border-radius: 16px; /* Restore the border-radius */
    min-height: 40vh;
  }
}
</style>

<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-center justify-content-center px-0"
    >
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center table-card-row"
        no-gutters
      >
        <b-col >
          <b-card
            class="left-container-card d-flex flex-row align-items-start justify-content-start border-0"
          >
            <b-row
              class="d-flex flex-row align-items-start justify-content-start w-100 card-title"
              no-gutters
              >Add a new administrator</b-row
            >
            <b-row
              class="d-flex flex-row align-items-start justify-content-start w-100"
              no-gutters
            >
              <form
                id="admin-form"
                class="d-flex flex-column align-items-start justify-content-start w-100"
              >
                <!-- full row input (Name) -->
                <b-row
                  class="d-flex flex-row align-items-start justify-content-start w-100 mt-3"
                  no-gutters
                >
                  <b-col cols="12">
                    <FormInput
                      groupId="admin-name-group"
                      id="admin-name-group-input"
                      class="text-prime-gray rounded-2"
                      label="Name"
                      v-model="name"
                      :state="nameError.status"
                      :invalidFeedback="`${nameError.message}`"
                      placeholder="Name"
                      isRequired
                      :whiteBG="false"
                      trim
                      form="admin-form"
                      type="text"
                    ></FormInput>
                  </b-col>
                </b-row>

                <!-- full row input (Email) -->
                <b-row
                  class="d-flex flex-row align-items-start justify-content-start w-100 mt-2"
                  no-gutters
                >
                  <b-col cols="12">
                    <FormInput
                      groupId="admin-name-group"
                      id="admin-name-group-input"
                      class="text-prime-gray rounded-2"
                      label="Email Address"
                      v-model="email"
                      :state="emailError.status"
                      :invalidFeedback="`${emailError.message}`"
                      placeholder="user@example.com"
                      isRequired
                      :whiteBG="false"
                      trim
                      form="admin-form"
                      type="text"
                    ></FormInput>
                  </b-col>
                </b-row>

                <!-- full row input (Password) -->
                <b-row
                  class="d-flex flex-row align-items-start justify-content-start w-100 mt-2"
                  no-gutters
                >
                  <b-col cols="12">
                    <FormInputGroup
                      groupId="password-group"
                      id="password-group-input"
                      v-model="password"
                      :state="passwordError.status"
                      :invalidFeedback="`${passwordError.message}`"
                      label="Password"
                      placeholder="Minimum 6 characters"
                      isRequired
                      :whiteBG="false"
                      :type="showPassword ? 'text' : 'password'"
                      form="login-form"
                      isAppend
                    >
                      <template v-slot:append>
                        <b-button
                          class="px-3 px-md-4 rounded-0"
                          variant="transparent"
                          @click="showPassword = !showPassword"
                        >
                          <b-icon
                            :icon="showPassword ? 'eye' : 'eye-slash'"
                            aria-label="Help"
                          ></b-icon>
                        </b-button>
                      </template>
                    </FormInputGroup>
                  </b-col>
                </b-row>

                <!-- form action -->
                <b-row
                  class="d-flex flex-row align-items-center justify-content-center w-100"
                  no-gutters
                >
                  <!-- admin create button -->
                  <b-col
                    class="d-flex flex-column align-items-center justify-content-center px-0 py-2"
                    cols="12"
                    md="10"
                  >
                    <FormButton
                      isBlock
                      isPill
                      variant="main-green"
                      class="font-weight-normal text-secondary mt-3 admin-button"
                      type="submit"
                      @click.native="createAdmin"
                      ><span class="text-white">Create Admin</span>
                    </FormButton>
                  </b-col>
                </b-row>
              </form>
            </b-row>
          </b-card>
        </b-col>
        <b-col >
          <b-card
            class="right-container-card  align-items-start justify-content-start border-0 " no-body
           
          >
            <b-row
              class="d-flex flex-row align-items-start justify-content-start w-100 card-title"
              no-gutters
              >Administrator management
            </b-row>
            <!-- table row -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-start w-100 table-container-row"
              no-gutters
            >
           
           
                <b-table
                  ref="selectableTable"
                  table
                  :bordered="false"
                  :hover="false"
                  head-variant="white"
                  sticky-header
                
                  show-empty
                  class="table-element"
                  thead-class="table-header font-secondary"
                  tbody-class="table-body"
                  :busy="isLoading"
                  :fields="tableHeader"
                  :items="tableItems"
                  :sortDesc="true"
                  :responsive="true"
                >
                  <!-- sortBy="name" -->
                  <!-- table busy state -->
                  <template #table-busy>
                    <div class="text-center text-muted my-3">
                      <b-spinner class="align-middle"></b-spinner>
                      <span class="text-left mx-1">Loading...</span>
                    </div>
                  </template>
                  <!-- table empty state -->
                  <template #empty="scope">
                    <b-row
                      class="d-flex flex-row align-items-center justfiy-content-center py-5"
                      no-gutters
                    >
                      <h6 class="text-center mb-0 mx-auto my-5">
                        {{ scope.emptyText }}
                      </h6>
                    </b-row>
                  </template>

                  <!-- type -->
                  <template #cell(type)="data">
                    <span class="main-text">{{ data.value.id }}</span
                    ><br />
                    <span class="sub-text">{{formatDate( data.value.date )}}</span>
                  </template>

                  <!-- name -->
                  <template #cell(name)="data">
                    <span class="main-text mb-2">{{ data.value.name }}</span
                    ><br />
                    <span class="sub-text mb-2">{{ data.value.email }}</span>
                  </template>

                  <!-- status -->
                  <template #cell(status)="data">
                    <template v-if="data.value == 'Enabled'">
                      <b-button
                        class="p-0"
                        style="background-color: transparent; border: none"
                        @click="statusInfo(data.item.type.id, 'Disabled', true)"
                      >
                        <b-icon
                          icon="check-lg"
                          aria-label="View More Actions"
                          class="mx-auto check-icon"
                          size="lg"
                          font-scale="1.2"
                        ></b-icon>
                        <span class="main-text-1 mb-2">{{ data.value }}</span>
                      </b-button>
                    </template>
                    <template v-if="data.value == 'Disabled'">
                      <b-button
                        style="background-color: transparent; border: none"
                        @click="statusInfo(data.item.type.id, 'Enabled', true)"
                      >
                        <b-icon
                          icon="slash-circle"
                          aria-label="View More Actions"
                          class="mx-auto slash-circle-icon"
                          size="lg"
                          font-scale="1.2"
                        ></b-icon>
                        <span class="main-text-2 mb-2">{{ data.value }}</span>
                      </b-button>
                    </template>
                  </template>

                  <!-- actions -->
                  <template #cell(actions)="data">
                    <!-- <span class="main-text mb-2">{{ data.value }}</span> -->
                    <b-dropdown id="dropdown-dropup" variant="white" no-caret>
                      <template #button-content>
                        <b-icon
                          icon="three-dots"
                          aria-label="View More Actions"
                          class="mx-auto more-icon"
                          size="lg"
                          font-scale="1.2"
                          @click="viewMore()"
                        ></b-icon>
                      </template>
                      <b-dropdown-item>Actions</b-dropdown-item>

                      <b-dropdown-item-button
                        variant="main-green"
                        button-class="px-4 pt-1"
                        @click="sendInfo(data.item.type.id, dltBtn)"
                      >
                        <b-icon
                          icon="trash"
                          class="pt-1 text-main-green"
                          scale="1.1"
                          aria-hidden="true"
                          style="color: red"
                        ></b-icon>
                        <span class="text-left text-small text-main-green pl-2"
                          >Delete Admin</span
                        >
                      </b-dropdown-item-button>
                    </b-dropdown>
                  </template>
                </b-table>
            
            </b-row>

            <!-- table controls -->
            <!-- safari fix (flex-shrink-0) -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-start justify-content-md-start w-100 pagination-styles"
              no-gutters
            >
             
              <b-col
                class="d-flex flex-column align-items-end align-items-sm-end align-items-md-end align-items-xl-end justify-content-center"
                cols="6"
              >
                <b-form-group
                  label="Per page"
                  label-cols="6"
                  label-cols-sm="6"
                  label-align="left"
                  label-align-sm="right"
                  label-size="sm"
                  label-for="perPageSelect"
                  class="mb-0 w-100"
                >
                  <b-form-select
                    v-model="perPage"
                    id="perPageSelect"
                    size="sm"
                    class="per-page-select border-1 border-main-green py-0"
                    :options="pageOptions"
                  ></b-form-select>
                </b-form-group>
              </b-col>

          
              <b-col
                class="d-flex flex-column align-items-center align-items-sm-center justify-content-center"
                cols="6"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="right"
                  size="md"
                  limit="4"
                  pills
                  class="table-pagination"
                  page-class="custom-page-item"
                  ellipsis-class="custom-page-item"
                  first-class="custom-page-item"
                  last-class="custom-page-item"
                  next-class="custom-page-item"
                  prev-class="custom-page-item"
                >
                  <template #first-text>
                    <b-icon
                      class="text-main-green"
                      icon="chevron-double-left"
                      size="sm"
                      aria-label="Plus"
                    ></b-icon>
                  </template>
                  <template #prev-text>
                    <b-icon
                      class="text-main-green"
                      icon="chevron-left"
                      size="sm"
                      aria-label="Plus"
                    ></b-icon>
                  </template>
                  <template #next-text>
                    <b-icon
                      class="text-main-green"
                      icon="chevron-right"
                      size="sm"
                      aria-label="Plus"
                    ></b-icon>
                  </template>
                  <template #last-text>
                    <b-icon
                      class="text-main-green"
                      icon="chevron-double-right"
                      size="sm"
                      aria-label="Plus"
                    ></b-icon>
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-modal
        id="modal-center"
        size="lg"
        title="Are you sure you want to delete this Admin?"
        centered
        v-model="show"
        @hide="closeModal()"
      >
        <template #modal-header-close>
          <!-- Emulate built in modal header close button action -->
          <!-- <h6>Are you sure you want to delete this Admin?</h6> -->
          <b-icon
            icon="x-circle"
            class="text-dark"
            @click="closeModal()"
            aria-label="Close Modal"
            font-scale="0.8"
          ></b-icon>
        </template>
        <p class="my-4">
          Are you sure you want to delete this Admin {{ this.custome_name }}?
          All the data will be permanently removed. This action cannot be
          undone.
        </p>
        <template #modal-footer="{}">
          <b-row flex-row align-items-center justify-content-center>
            <b-col
              d-flex
              flex-row
              align-items-center
              justify-content-center
              align-self="center"
              style="padding-right: 0px"
            >
              <FormButton
                isPill
                class="font-weight-normal text-secondary mt-2 login-button"
                style="background-color: #bdbdbd; border: none"
                @click.native="closePopup"
                ><span class="text-white">Cancel</span></FormButton
              >
            </b-col>
            <b-col
              d-flex
              flex-row
              align-items-center
              justify-content-center
              align-self="center"
            >
              <FormButton
                isPill
                class="font-weight-normal text-secondary mt-2 login-button"
                @click.native="DeleteAdmin"
                style="background-color: #0b9b7d; border: none"
                ><span class="text-white">Delete</span></FormButton
              >
            </b-col>
          </b-row>
        </template>
      </b-modal>
      <b-modal
        id="modal-center"
        size="lg"
        title="Are you sure you want to enable this Admin?"
        centered
        v-model="showStatusEnable"
        @hide="closeModal()"
      >
        <template #modal-header-close>
          <!-- Emulate built in modal header close button action -->
          <!-- <h6>Are you sure you want to enable this Admin?</h6> -->
          <b-icon
            icon="x-circle"
            class="text-dark"
            @click="closeModal()"
            aria-label="Close Modal"
            font-scale="0.8"
          ></b-icon>
        </template>
        <p class="my-4">
          Are you sure you want to enable this Admin {{ this.custome_name }}?
          This action can always be revoked.
        </p>
        <template #modal-footer="{}">
          <b-row flex-row align-items-center justify-content-center>
            <b-col
              d-flex
              flex-row
              align-items-center
              justify-content-center
              align-self="center"
              style="padding-right: 0px"
            >
              <FormButton
                isPill
                class="font-weight-normal text-secondary mt-2 login-button"
                style="background-color: #bdbdbd; border: none"
                @click.native="closePopup"
                ><span class="text-white">Cancel</span></FormButton
              >
            </b-col>
            <b-col
              d-flex
              flex-row
              align-items-center
              justify-content-center
              align-self="center"
            >
              <FormButton
                isPill
                class="font-weight-normal text-secondary mt-2 login-button"
                @click.native="StatusEnableFn"
                style="background-color: #0b9b7d; border: none"
                ><span class="text-white">Enable</span>
              </FormButton>
            </b-col>
          </b-row>
        </template>
      </b-modal>
      <b-modal
        id="modal-center"
        size="lg"
        title="Are you sure you want to disable this Admin?"
        centered
        v-model="showStatusDisable"
        @hide="closeModal()"
      >
        <template #modal-header-close>
          <!-- Emulate built in modal header close button action -->
          <!-- <h6>Are you sure you want to disable this Admin?</h6> -->
          <b-icon
            icon="x-circle"
            class="text-dark"
            @click="closeModal()"
            aria-label="Close Modal"
            font-scale="0.8"
          ></b-icon>   
        </template>
        <p class="my-4">
          Are you sure you want to disable this Admin {{ this.custome_name }}?
          This action can always be revoked.
        </p>
        <template #modal-footer="{}">
          <b-row flex-row align-items-center justify-content-center>
            <b-col
              d-flex
              flex-row
              align-items-center
              justify-content-center
              align-self="center"
              style="padding-right: 0px"
            >
              <FormButton
                isPill
                class="font-weight-normal text-secondary mt-2 login-button"
                style="background-color: #bdbdbd; border: none"
                @click.native="closePopup"
                ><span class="text-white">Cancel</span></FormButton
              >
            </b-col>
            <b-col
              d-flex
              flex-row
              align-items-center
              justify-content-center
              align-self="center"
            >
              <FormButton
                isPill
                class="font-weight-normal text-secondary mt-2 login-button"
                @click.native="StatusDisableFn"
                style="background-color: #0b9b7d; border: none"
                ><span class="text-white">Disable</span>
              </FormButton>
            </b-col>
          </b-row>
        </template>
      </b-modal>
    </b-container>
  </HomeLayout>
</template>

<script>
import HomeLayout from "@/layout/HomeLayout";
import FormInput from "@/components/Form/FormInput";
import FormInputGroup from "@/components/Form/FormInputGroup";
import FormButton from "@/components/Form/FormButton";
import CheckEmail from "@/util/CheckEmail";

// services
import {
  GetAllAdmins,
  DeleteAdminMaster,
  ChangeAdminStatus,
} from "@/services/administrators.service";

import axios from "axios";

export default {
  name: "Administrators",
  components: {
    HomeLayout,
    FormInput,
    FormInputGroup,
    FormButton,
  },
  data() {
    return {
      name: "",
      email: "",
      password: "",
      showPassword: false,
      showStatusEnable: false,
      showStatusDisable: false,
      Admin_ID: null,
      status: "",
      statusBtn: false,
      custome_name: null,
      dltBtn: false,
      show: false,
      nameError: {
        status: null,
        message: "",
      },
      emailError: {
        status: null,
        message: "",
      },
      passwordError: {
        status: null,
        message: "",
      },
      tableHeader: [
        {
          key: "type",
          label: "Type",
          class: "text-left",
          sortable: true,
        },
        {
          key: "name",
          label: "Name",
          class: "text-left",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          class: "text-left",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          class: "text-center",
          sortable: true,
        },
      ],
      tableItems: [],
      // table pagination controls
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 20],
      totalRows: 0,
    };
  },
  watch: {
    perPage() {
      this.initFn();
    },
    currentPage() {
      this.initFn();
    },
    name(value) {
      if (!value) {
        this.nameError.status = false;
        this.nameError.message = "Please fill in your name ";
      } else {
        this.nameError.status = true;
      }
    },
    email(value) {
      if (value) {
        if (CheckEmail(value) == true) this.emailError.status = true;
        else {
          this.emailError.status = false;
          this.emailError.message = "Please enter a valid email address";
        }
      } else {
        this.emailError.status = false;
        this.emailError.message = "Please fill in your email address";
      }
    },
    password(value) {
      if (value) {
        if (value.length < 6) {
          this.passwordError.status = false;
          this.passwordError.message =
            "A minimum length of 6 characters needed";
        } else {
          this.passwordError.status = true;
        }
      } else {
        this.passwordError.status = false;
        this.passwordError.message = "Please fill in your password";
      }
    },
  },
  async mounted() {
    await this.initFn();
  },
  methods: {
    async createAdmin() {
      try {
        const payload = {
          email: this.email,
          password: this.password,
          name: this.name,
        };
        const response = await axios.post("/admin/register", payload);
        if (response.status == 200) {
          this.$bvToast.toast("Success", {
            title: "Admin created Successfully",
            toaster: "b-toaster-bottom-right",
            variant: "success",

            solid: true,
          });
          this.resetFn();
          this.initFn();
          console.log(response);
        } else {
          this.$bvToast.toast("Registration failed", {
            title: "Registration failed",
            toaster: "b-toaster-bottom-right",
            variant: "danger",

            solid: true,
          });
        }
        return response;
      } catch (error) {
        console.log(error.response);

        this.$bvToast.toast("Email has already been registred", {
          title: "Conflict",
          toaster: "b-toaster-bottom-right",
          variant: "danger",

          solid: true,
        });
        throw error;
      }
    },
    async initFn(val = undefined) {
      try {
        if (val) this.currentPage = val;
        this.isLoading = true;
        let { data } = await GetAllAdmins({
          limit: this.perPage,
          page: this.currentPage,
        });
        this.totalRows = data.total;
        this.tableItems = data.results.map((x) => {
          return {
            type: {
              id: x._id,
              date: x.created_at.split("T")[0],
            },
            name: {
              name: x.name,
              email: x.email,
            },
            status: x.status,
          };
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    async StatusDisableFn() {
      console.log("id", this.Admin_ID);
      console.log("status", this.status);
      let payload = {
        id: this.Admin_ID,
        status: this.status,
      };
      console.log("payload", payload);
      let { data } = await ChangeAdminStatus(payload);
      console.log("data", data);
      this.initFn();
      this.showStatusDisable = false;
      this.customersItems = data.map((x) => {
        return {
          Status: x.status,
        };
      });
    },

    async StatusEnableFn() {
      console.log("id", this.Admin_ID);
      console.log("status", this.status);
      let payload = {
        id: this.Admin_ID,
        status: this.status,
      };
      console.log("payload", payload);
      let { data } = await ChangeAdminStatus(payload);
      console.log("data", data);
      this.initFn();
      this.showStatusEnable = false;
      this.customersItems = data.map((x) => {
        return {
          Status: x.status,
        };
      });
    },
    validateForm() {
      if (!this.name) {
        this.nameError.status = false;
        this.nameError.message = "Please fill in your email address";
      }
      if (!this.email) {
        this.emailError.status = false;
        this.emailError.message = "Please fill in your email address";
      }

      if (!this.password) {
        this.passwordError.status = false;
        this.passwordError.message = "Please fill in your password";
      }

      if (this.password < 6) {
        this.passwordError.status = false;
        this.passwordError.message = "A minimum length of 6 characters needed";
      }
    },
    resetFn() {
      // this.isReset = true;
      this.name = null;
      this.email = null;
      this.password = null;

      setTimeout(() => {
        this.nameError.status = null;
        this.emailError.status = null;
        this.passwordError.status = null;
      }, 100);
    },
    async addAdmin() {},
    async DeleteAdmin() {
      try {
        console.log("AdminID", this.Admin_ID);
        this.isLoading = true;
        let payload = {
          id: this.Admin_ID,
        };
        let { data, status } = await DeleteAdminMaster(payload);
        if (status == 200) {
          console.log("Admin deleted", data);
          this.isLoading = false;
          this.$bvToast.toast("Admin Deleted Successfully", {
            title: "Deleted Successfull",
            toaster: "b-toaster-bottom-right",
            variant: "success",
            solid: true,
          });
          this.initFn();
          this.show = false;
        }
      } catch (error) {
        console.log("Admin notdeleted");
      }
    },

    statusInfo(id, status, statusBtn) {
      console.log("ID", id);
      console.log("ID", status);
      this.Admin_ID = id;
      this.status = status;
      // this.custome_name = cname
      this.statusBtn = statusBtn;
      if (this.status == "Enabled") {
        this.showStatusEnable = true;
      } else {
        this.showStatusDisable = true;
      }
    },
    sendInfo(id, dltBtn) {
      console.log("ID", id);
      this.Admin_ID = id;
      // this.custome_name = cname
      this.dltBtn = dltBtn;
      this.show = true;
    },
    closePopup() {
      this.show = false;
      this.showStatusDisable = false;
      this.showStatusEnable = false;
    },
    formatDate(val) {
      if (val) {
        const dates = val.split("-");
        return `${dates[2]}/${dates[1]}/${dates[0]}`;
      }
    },
  },
};
</script>

<style scoped>
/* table card */

.table-card-row {
  /* height: 80vh; */
  width: 90vw;
}
.left-container-card {
  background-color: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.1);
  /* box-sizing: border-box; */
  /* position: relative; */
  /* width: calc(100% - 100px); */
  /* width: 100%; */
  min-height: 80vh;
  padding: 10px;
  /* height: 100%; */
  /* left: 0; */
  /* right: 0; */

  /* right: 40px; */
  /* bottom: 45px; */
  border: 1px solid rgba(135, 147, 163, 0.25);
  border-radius: 16px 0px 0px 16px;
}
.right-container-card {
  background-color: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  /* position: relative; */
  /* width: calc(100% - 100px); */
  /* width: 100%; */
  min-height: 80vh;
  padding: 10px;
  /* left: 0; */
  /* right: 0; */

  /* right: 40px; */
  /* bottom: 45px; */
  border: 1px solid rgba(135, 147, 163, 0.25);
  border-radius: 0px 16px 16px 0px;
}
.card-title {
  font-family: "Lato";
  font-weight: 400;
  font-size: 16px;
  color: #333333;
}
.admin-button {
  width: 162px;
  height: 50px;
  background-color: #0b9b7d;
  border-radius: 30px;
}
/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}
/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(33, 150, 83, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--white);
}

.table-container-row::-webkit-scrollbar-thumb {
  background-color: var(--white);
}

.table-container-row .table-element {
  /* width: 100%; */
  /* min-width: 600px;
  min-height: 40vh;
  scrollbar-width: thin; */
   width: 100%;
   min-height: 70vh;
  scrollbar-width: thin;
}

/* table element css */
.table-element >>> .table-header {
  background-color: transparent;

  /* align-items: flex-start; */
}

.table-element >>> .table-header th {
  /* color: var(--prime-gray); */
  color: #8793a3;
  font-family: "Lato";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  border-color: transparent;
}

.table-element >>> .table-body td {
  vertical-align: middle;
  border-color: transparent;
}

.sub-text {
  color: #8793a3;
}

.main-text-1 {
  color: #1c72ff;
}

.main-text-2 {
  color: #b22234;
}

.more-icon {
  background: #ffffff;
  border: 1px solid #333333;
  border-radius: 100px;
  padding: 4px;
  width: 35px;
  height: 35px;
}

.more-icon:hover {
  background: #333333;
  color: #ffffff;
}

.check-icon {
  color: #1c72ff;
  width: 14px;
  height: 14px;
  padding-right: 23px;
}

.slash-circle-icon {
  color: #b22234;
  width: 14px;
  height: 14px;
  padding-right: 23px;
}

/* table pagination */
.table-pagination >>> .custom-page-item button,
.table-pagination >>> .custom-page-item span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #333333;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  width: 38px;
  height: 34px;
  padding: 4px;
  border: transparent;
  border-radius: 50%;
}

.pagination-styles {
  position: absolute;
  bottom: 0;
  right: 0;
}

/* table per page selector */
.per-page-select {
  cursor: pointer;
  width: 65px;
}
.login-button {
  border-radius: 30px;
  width: 142px;
  height: 30px;
  background: #0b9b7d;
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {

.left-container-card {
background-color: var(--white);
/* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.1); */
box-sizing: border-box;
position: relative;
/* width: calc(100% - 100px); */
/* width: 100%; */
min-height: 40vh;
padding: 10px;
left: 0;
right: 0;

/* right: 40px; */
/* bottom: 45px; */
border: 1px solid rgba(135, 147, 163, 0.25);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.1);
border-radius: 16px 16px 0px 0px;
}
.right-container-card {
  background-color: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  /* position: relative; */
  /* width: calc(100% - 100px); */
  /* width: 100%; */
  min-height: 50vh;
  padding: 10px;
  /* left: 0; */
  /* right: 0; */

  /* right: 40px; */
  /* bottom: 45px; */
  border: 1px solid rgba(135, 147, 163, 0.25);
  border-radius: 0px 16px 16px 0px;
}
.table-container-row .table-element {
  /* width: 100%; */
  /* min-width: 600px;
  min-height: 40vh;
  scrollbar-width: thin; */
   width: 100%;
 min-height: 40vh;
  scrollbar-width: thin;
}
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

.left-container-card {
background-color: var(--white);
/* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.1); */
box-sizing: border-box;
position: relative;
/* width: calc(100% - 100px); */
/* width: 100%; */
min-height: 45vh;
padding: 10px;
left: 0;
right: 0;

/* right: 40px; */
/* bottom: 45px; */
border: 1px solid rgba(135, 147, 163, 0.25);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.1);
border-radius: 16px 16px 0px 0px;
}
.right-container-card {
  background-color: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  /* position: relative; */
  /* width: calc(100% - 100px); */
  /* width: 100%; */
  min-height: 50vh;
  padding: 10px;
  /* left: 0; */
  /* right: 0; */

  /* right: 40px; */
  /* bottom: 45px; */
  border: 1px solid rgba(135, 147, 163, 0.25);
  border-radius: 0px 0px 16px 16px;
}

.table-container-row .table-element {
  /* width: 100%; */
  /* min-width: 600px;
  min-height: 40vh;
  scrollbar-width: thin; */
   width: 100%;
 min-height: 45vh;
  scrollbar-width: thin;
}
}
</style>

<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-center justify-content-center px-0"
    >
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center table-card-row"
        no-gutters
      >
        <b-col >
          <b-card
            class="left-container-card d-flex flex-row align-items-start justify-content-start border-0 w-100"
          >
            <b-row
              class="d-flex flex-row align-items-start justify-content-start w-100 card-title"
              no-gutters
              >Create a new coupon</b-row
            >
            <b-row
              class="d-flex flex-row align-items-start justify-content-start w-100"
              no-gutters
            >
              <form
                id="admin-form"
                class="d-flex flex-column align-items-start justify-content-start w-100"
              >
                <!-- full row input (Name) -->
                <b-row
                  class="d-flex flex-row align-items-start justify-content-start w-100 mt-3"
                  no-gutters
                >
                  <b-col cols="12">
                    <FormInputGroup
                      groupId="password-group"
                      id="password-group-input"
                      class="text-prime-gray rounded-2"
                      label="Coupon"
                      v-model="Coupon"
                      placeholder="Coupon"
                      isRequired
                      :state="couponError.status"
                      :invalidFeedback="`${couponError.message}`"
                      :whiteBG="false"
                      trim
                      form="admin-form"
                      type="text"
                      isAppend
                    >
                      <template v-slot:append>
                        <b-button
                          class="px-3 px-md-4 rounded-2"
                          style="
                            background-color: #eeeeee;
                            border-bottom: none;
                            border-right: none;
                            border-top: none;
                          "
                          @click="generateCode"
                        >
                          <!-- @click="showCoupon = !showCoupon" -->

                          <b-icon
                            style="color: #828282"
                            :icon="showCoupon ? ' key ' : ' key '"
                            aria-label="Help"
                          ></b-icon>
                          <span style="color: #828282; padding-left: 3px"
                            >Securely Generate</span
                          >
                        </b-button>
                      </template>
                    </FormInputGroup>
                  </b-col>
                </b-row>

                <!-- full row input (Email) -->
                <b-row
                  class="d-flex flex-row align-items-start justify-content-start w-100 mt-2"
                  no-gutters
                >
                  <b-col cols="12">
                    <FormInputGroup
                      groupId="password-group"
                      id="password-group-input"
                      class="text-prime-gray rounded-2"
                      label="Discount value"
                      v-model="Discountvalue"
                      placeholder="Discount value"
                      isRequired
                      :state="discountvalueError.status"
                      :invalidFeedback="`${discountvalueError.message}`"
                      :whiteBG="false"
                      trim
                      form="admin-form"
                      type="number"
                      isAppend
                    >
                      <template v-slot:append>
                        <b-button
                          class="px-2 px-md-4 rounded-2"
                          style="
                            background-color: #eeeeee;
                            border-bottom: none;
                            border-right: none;
                            border-top: none;
                          "
                          @click="showDiscount = !showDiscount"
                        >
                          <b-icon
                            style="color: #828282"
                            :icon="showDiscount ? 'percent' : 'percent'"
                            aria-label="Help"
                          ></b-icon>
                        </b-button>
                      </template>
                    </FormInputGroup>
                  </b-col>
                </b-row>

                <!-- full row input (Password) -->
                <b-row
                  class="d-flex flex-row align-items-start justify-content-start w-100 mt-2"
                  no-gutters
                >
                  <b-col cols="12">
                    <FormInputGroup
                      groupId="password-group"
                      id="password-group-input"
                      :value="formatDate(Expirydate)"
                      label="Expirydate"
                      placeholder="Expirydate"
                      isRequired
                      :state="expirydatevalueError.status"
                      :invalidFeedback="`${expirydatevalueError.message}`"
                      :whiteBG="false"
                      type="text"
                      form="login-form"
                      isAppend
                      @click="openDatePicker"
                    >
                      <template v-slot:append>
                        <b-form-datepicker
                          ref="datepicker"
                          class="date-picker-btn font-primary datepicker align-items-center px-3 px-md-3 rounded-2"
                          v-model="Expirydate"
                          no-flip
                          size="sm"
                          button-only
                          button-variant="#EEEEEE"
                          locale="en-US"
                          right
                          nav-button-variant="main-green"
                          today-variant="main-green"
                          menu-class="rounded-lg custom-calendar-dropdown text-prime-gray"
                          aria-controls="start-date-input"
                          style="background-color: #eeeeee"
                        ></b-form-datepicker>
                      </template>
                    </FormInputGroup>
                  </b-col>
                </b-row>

                <!-- full row input (country) -->
                <!-- <b-row
                  class="d-flex flex-row align-items-start justify-content-start w-100 mt-2"
                  no-gutters
                >
                  <b-col cols="12">
                    <FormSelect
            groupId="type-group"
            id="type-group-input"
            class="text-prime-gray rounded-2"
           
            label="Country"
            v-model="Country"
            :whiteBG="false"
            :options="CountryOptions"
            form="login-form"
            placeholder="Default"
          ></FormSelect>
                  </b-col>
                </b-row> -->
                 <!-- full row input (Packages) -->
                 <b-row
                  class="d-flex flex-row align-items-start justify-content-start w-100 mt-2"
                  no-gutters
                >
                  <b-col cols="12">
                    <b-dropdown
                      id="dropdown-form"
                      ref="dropdown"
                      variant="light"
                      class="w-100 m-0 py-0"
                      toggle-class="border border-dark-subtle bg-white rounded-lg border border-dark"
                      menu-class="w-100 bg-white border border-dark-subtle border-5 py-0 filter-dropdown-menu"
                      left
                      no-caret
                      no-flip
                      text="Button text via Prop"
                  >
                    <template v-slot:button-content>
                      <b-button
                          block
                          id="customer-modal-btn"
                          variant="transparent"
                          class="w-100 d-flex  align-items-center justify-content-between
               rounded-lg p-0 m-0"
                      >
                    <span :class="selectedPackage ?'':'text-color'">
                      {{ selectedPackage ? selectedPackage :"Select a Package (Optional)" }}
                      </span>
                        <b-icon
                            icon="chevron-down"
                            class="text-primary"
                            font-scale="0.9"
                        ></b-icon>
                      </b-button>
                    </template>
                    <b-dropdown-item
                        @click="() => getPackage(status)"
                        href="#"
                        v-for="(status, index) in PackageOptions"
                        :key="index"
                    >
                      {{ status }}
                    </b-dropdown-item>
                  </b-dropdown>
                  </b-col>
                </b-row>


                <!-- form action -->
                <b-row
                  class="d-flex flex-row align-items-center justify-content-center w-100"
                  no-gutters
                >
                  <!-- admin create button -->
                  <b-col
                    class="d-flex flex-column align-items-center justify-content-center px-0 py-2"
                    cols="12"
                    md="10"
                  >
                    <FormButton
                      isBlock
                      isPill
                      variant="main-green"
                      class="font-weight-normal text-secondary mt-3 admin-button"
                      type="submit"
                      @click.native="createCoupon"
                      ><span class="text-white">Create Coupon</span>
                    </FormButton>
                  </b-col>
                </b-row>
              </form>
            </b-row>
          </b-card>
        </b-col>
        <b-col >
          <b-card
            class="right-container-card  align-items-start justify-content-start border-0 " no-body
          >
            <b-row
              class="d-flex flex-row align-items-start justify-content-start w-100 card-title"
              no-gutters
              >Coupon management
            </b-row>
            <!-- table actions row -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-start w-100"
             
              no-gutters
            >
              <!-- search input column -->
              <b-col
                class="d-flex flex-column align-items-start justify-content-start px-4 px-lg-0 py-2 py-lg-0"
                cols="10"
               
              >
                <!-- table search input -->
                <b-input-group class="search-input-group">
                  <b-input-group-append>
                    <b-row
                      class="d-flex flex-row align-items-center justify-content-center px-3 border-1 rounded-right"
                      no-gutters
                    >
                      <b-icon
                        class="border-1"
                        style="color: rgba(135, 147, 163, 1)"
                        icon="search"
                        size="lg"
                        aria-label="Search"
                      ></b-icon>
                    </b-row>
                  </b-input-group-append>
                  <b-form-input
                    class="search-input border-1"
                    type="text"
                    placeholder="Search Coupons"
                    v-model="searchTable"
                    @keyup.native.enter="searchFn"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-row
                      class="d-flex flex-row align-items-center justify-content-center px-3 border-1 rounded-right"
                      no-gutters
                    >
                      <template v-if="searchTable">
                        <b-icon
                          class="text-main-green mr-2 border-1"
                          icon="x"
                          size="lg"
                          aria-label="Search"
                          @click="close"
                        ></b-icon>
                      </template>
                    </b-row>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>

            <!-- table row -->
            <b-row
            class="d-flex flex-row align-items-start justify-content-start w-100 table-container-row "
            no-gutters
          >
             
                <b-table
                  ref="selectableTable"
                  table
                  :bordered="false"
                  :hover="false"
                  sticky-header
                  head-variant="white"
                  selectable
                  show-empty
                  select-mode="multi"
                  class="table-element"
                  thead-class="table-header font-secondary"
                  tbody-class="table-body"
                  :busy="isLoading"
                  :fields="tableHeader"
                  :items="tableItems"
                  sortBy="type"
                  :sortDesc="true"
                >

                  <!-- Date Created -->
                  <template #cell(datecreated)="data">
                    <span class="main-text">{{ formatDate(data.value ) }}</span>
                  </template>

                <!-- Coupon code -->
                <template #cell(couponCode)="data">
                  <span class="main-text mb-2">{{ data.value }}</span
                  ><br />
                </template>

                <!-- status -->
                <template #cell(status)="data">
                  <template v-if="data.value == 'Enabled'">
                    <b-button
                      style="background-color: transparent; border: none"
                      @click="statusInfo(data.item.type.id, 'Disabled', true)"
                    >
                      <b-icon
                        icon="check-lg"
                        aria-label="View More Actions"
                        class="mx-auto check-icon"
                        size="lg"
                        font-scale="1.2"
                      ></b-icon>
                      <span class="main-text-1 mb-2">{{ data.value }}</span>
                    </b-button>
                  </template>
                  <template v-if="data.value == 'Disabled'">
                    <b-button
                      style="background-color: transparent; border: none"
                      @click="statusInfo(data.item.type.id, 'Enabled', true)"
                    >
                      <b-icon
                        icon="slash-circle"
                        aria-label="View More Actions"
                        class="mx-auto slash-circle-icon"
                        size="lg"
                        font-scale="1.2"
                      ></b-icon>
                      <span class="main-text-2 mb-2">{{ data.value }}</span>
                    </b-button>
                  </template>
                </template>

                <!-- Discount -->
                <template #cell(discount)="data">
                  <span class="main-text">{{ data.value }}</span>
                </template>

                <!-- ExpirayDate -->
                <template #cell(expiryDate)="data">
                  <span class="main-text">{{formatDate(data.value ) }}</span>
                </template>

                <!-- actions -->
                <template #cell(actions)="data">
                  <!-- <span class="main-text mb-2">{{ data.value }}</span> -->
                  <b-dropdown id="dropdown-dropup" variant="white" no-caret>
                    <template #button-content>
                      <b-icon
                        icon="three-dots"
                        aria-label="View More Actions"
                        class="mx-auto more-icon"
                        size="lg"
                        font-scale="1.2"
                        @click="viewMore()"
                      ></b-icon>
                    </template>
                    <b-dropdown-item>Actions</b-dropdown-item>

                      <b-dropdown-item-button
                        variant="main-green"
                        button-class="px-4 pt-1"
                        @click="sendInfo(data.item.type.id, dltBtn)"
                      >
                        <b-icon
                          icon="trash"
                          class="pt-1 text-main-green"
                          scale="1.1"
                          aria-hidden="true"
                          style="color: red"
                        ></b-icon>
                        <span class="text-left text-small text-main-green pl-2"
                          >Delete Coupon</span
                        >
                      </b-dropdown-item-button>
                    </b-dropdown>
                  </template>
                </b-table>
              
            </b-row>

            <!-- table controls -->
            <!-- safari fix (flex-shrink-0) -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-start justify-content-md-start w-100 pagination-styles " 
              no-gutters
            >
              <!-- pages per page control -->
              <b-col
                class="d-flex flex-column align-items-end align-items-sm-end align-items-md-end align-items-xl-end justify-content-center"
                cols="6"
              >
                <b-form-group
                  label="Per page"
                  label-cols="6"
                  label-cols-sm="6"
                  label-align="left"
                  label-align-sm="right"
                  label-size="sm"
                  label-for="perPageSelect"
                  class="mb-0 w-100"
                >
                  <b-form-select
                    v-model="perPage"
                    id="perPageSelect"
                    size="sm"
                    class="per-page-select border-1 border-main-green py-0"
                    :options="pageOptions"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <!-- pagination controls -->
              <b-col
                class="d-flex flex-column align-items-center align-items-sm-center justify-content-center"
                cols="6"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  @change="loadPage"
                  align="right"
                  size="md"
                  limit="4"
                  pills
                  class="table-pagination"
                  page-class="custom-page-item"
                  ellipsis-class="custom-page-item"
                  first-class="custom-page-item"
                  last-class="custom-page-item"
                  next-class="custom-page-item"
                  prev-class="custom-page-item"
                >
                  <template #first-text>
                    <b-icon
                      class="text-main-green"
                      icon="chevron-double-left"
                      size="sm"
                      aria-label="Plus"
                    ></b-icon>
                  </template>
                  <template #prev-text>
                    <b-icon
                      class="text-main-green"
                      icon="chevron-left"
                      size="sm"
                      aria-label="Plus"
                    ></b-icon>
                  </template>
                  <template #next-text>
                    <b-icon
                      class="text-main-green"
                      icon="chevron-right"
                      size="sm"
                      aria-label="Plus"
                    ></b-icon>
                  </template>
                  <template #last-text>
                    <b-icon
                      class="text-main-green"
                      icon="chevron-double-right"
                      size="sm"
                      aria-label="Plus"
                    ></b-icon>
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-modal
        id="modal-center"
        size="lg"
        title="Are you sure you want to disable this Coupon?"
        centered
        v-model="showStatusDisable"
        @hide="closeModal()"
      >
        <template #modal-header-close>
          <!-- Emulate built in modal header close button action -->
          <!-- <h6>Are you sure you want to disable this Coupon?</h6> -->
          <b-icon
            icon="x-circle"
            class="text-dark"
            @click="closeModal()"
            aria-label="Close Modal"
            font-scale="0.8"
          ></b-icon>
        </template>
        <p class="my-4">
          Are you sure you want to disable this Coupon {{ this.coupon }}? This
          action can always be revoked.
        </p>
        <template #modal-footer="{}">
          <b-row flex-row align-items-center justify-content-center>
            <b-col
              d-flex
              flex-row
              align-items-center
              justify-content-center
              align-self="center"
              style="padding-right: 0px"
            >
              <FormButton
                isPill
                class="font-weight-normal text-secondary mt-2 login-button"
                style="background-color: #bdbdbd; border: none"
                @click.native="closePopup"
                ><span class="text-white">Cancel</span></FormButton
              >
            </b-col>
            <b-col
              d-flex
              flex-row
              align-items-center
              justify-content-center
              align-self="center"
            >
              <FormButton
                isPill
                class="font-weight-normal text-secondary mt-2 login-button"
                @click.native="StatusDisableFn"
                style="background-color: #0b9b7d; border: none"
                ><span class="text-white">Disable</span>
              </FormButton>
            </b-col>
          </b-row>
        </template>
      </b-modal>

      <b-modal
        id="modal-center"
        size="lg"
        title="Are you sure you want to enable this Coupon?"
        centered
        v-model="showStatusEnable"
        @hide="closeModal()"
      >
        <template #modal-header-close>
          <!-- Emulate built in modal header close button action -->
          <!-- <h6>Are you sure you want to enable this Coupon?</h6> -->
          <b-icon
            icon="x-circle"
            class="text-dark"
            @click="closeModal()"
            aria-label="Close Modal"
            font-scale="0.8"
          ></b-icon>
        </template>
        <p class="my-4">
          Are you sure you want to enable this Coupon {{ this.coupon }}? This
          action can always be revoked.
        </p>
        <template #modal-footer="{}">
          <b-row flex-row align-items-center justify-content-center>
            <b-col
              d-flex
              flex-row
              align-items-center
              justify-content-center
              align-self="center"
              style="padding-right: 0px"
            >
              <FormButton
                isPill
                class="font-weight-normal text-secondary mt-2 login-button"
                style="background-color: #bdbdbd; border: none"
                @click.native="closePopup"
                ><span class="text-white">Cancel</span></FormButton
              >
            </b-col>
            <b-col
              d-flex
              flex-row
              align-items-center
              justify-content-center
              align-self="center"
            >
              <FormButton
                isPill
                class="font-weight-normal text-secondary mt-2 login-button"
                @click.native="StatusEnableFn"
                style="background-color: #0b9b7d; border: none"
                ><span class="text-white">Enable</span>
              </FormButton>
            </b-col>
          </b-row>
        </template>
      </b-modal>
      <b-modal
        id="modal-center"
        size="lg"
        title="Are you sure you want to delete this Admin?"
        centered
        v-model="show"
        @hide="closeModal()"
      >
        <template #modal-header-close>
          <!-- Emulate built in modal header close button action -->
          <!-- <h6>Are you sure you want to delete this Admin?</h6> -->
          <b-icon
            icon="x-circle"
            class="text-dark"
            @click="closeModal()"
            aria-label="Close Modal"
            font-scale="0.8"
          ></b-icon>
        </template>
        <p class="my-4">
          Are you sure you want to delete this Coupon? All the data will be
          permanently removed. This action cannot be undone.
        </p>
        <template #modal-footer="{}">
          <b-row flex-row align-items-center justify-content-center>
            <b-col
              d-flex
              flex-row
              align-items-center
              justify-content-center
              align-self="center"
              style="padding-right: 0px"
            >
              <FormButton
                isPill
                class="font-weight-normal text-secondary mt-2 login-button"
                style="background-color: #bdbdbd; border: none"
                @click.native="closePopup"
                ><span class="text-white">Cancel</span></FormButton
              >
            </b-col>
            <b-col
              d-flex
              flex-row
              align-items-center
              justify-content-center
              align-self="center"
            >
              <FormButton
                isPill
                class="font-weight-normal text-secondary mt-2 login-button"
                @click.native="DeleteCoupon"
                style="background-color: #0b9b7d; border: none"
                ><span class="text-white">Delete</span></FormButton
              >
            </b-col>
          </b-row>
        </template>
      </b-modal>
    </b-container>
  </HomeLayout>
</template>
  
  <script>
import HomeLayout from "@/layout/HomeLayout";
import FormInputGroup from "@/components/Form/FormInputGroup";
import FormButton from "@/components/Form/FormButton";
// import FormSelect from "@/components/Form/FormSelect";
// services
import {
  GetAllCoupons,
  DeleteCoupons,
  ChangeCouponStatus,
  SearchCoupons,
  chkCouponName,
} from "@/services/coupon.service";
import axios from "axios";

export default {
  name: "Administrators",
  components: {
    HomeLayout,
    FormInputGroup,
    FormButton,
    // FormSelect
  },
  data() {
    return {
      selectedPackage:null,
      Coupon: "",
      Discountvalue: "",
      Expirydate: "",
      status: "",
      code: "",
      Coupon_ID: null,
      showCoupon: false,
      showDiscount: false,
      dltBtn: false,
      showPassword: false,
      showStatusEnable: false,
      showStatusDisable: false,
      statusBtn: false,
      show: false,
      searchTable: null,
      packages:null,
      Country:null,
      CountryOptions:["New Zealand", "Australia","Fiji"],
      PackageOptions:["Figa Green","Figa Blue"," Figa Accountant"],
      couponError: {
        status: null,
        message: "",
      },
      discountvalueError: {
        status: null,
        message: "",
      },
      expirydatevalueError: {
        status: null,
        message: "",
      },
      tableHeader: [
        {
          key: "datecreated",
          label: "Date Created",
          class: "text-left",
          sortable: true,
        },
        {
          key: "couponCode",
          label: "Coupon Code",
          class: "text-left",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          class: "text-left",
          sortable: true,
        },
        {
          key: "discount",
          label: "Discount",
          class: "text-left",
          sortable: true,
        },
        {
          key: "expiryDate",
          label: "ExpirayDate",
          class: "text-left",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          class: "text-center",
        },
      ],
      // tableItems: [
      //   {
      //     datecreated: "29/07/2021",
      //     couponCode: "v4dMWnyieag",
      //     discount: "20%",
      //     expiryDate: "29/07/2022",
      //     status: "Enabled",
      //   },
      //   {
      //     datecreated: "29/07/2021",
      //     couponCode: "v4dMWnyieag",
      //     discount: "20%",
      //     expiryDate: "29/07/2022",
      //     status: "Disabled",
      //   },
      // ],
      // table pagination controls
      tableItems: [],
      currentPage: 1,
      perPage: 4,
      pageOptions: [4, 10, 15, 20],
      totalRows: 0,
      limits: "",
    };
  },
  watch: {
    searchTable() {
      this.searchFn();
    },
    perPage() {
      this.initFn();
    },
    currentPage() {
      this.initFn();
      // this.loadPage(1);
    },
    Coupon(value) {
      if (!value) {
        this.couponError.status = false;
        this.couponError.message = "Please fill in Coupon ";
      } else {
        this.couponError.status = true;
      }
    },
    Discountvalue(value) {
      if (!value) {
        this.discountvalueError.status = false;
        this.discountvalueError.message = "Please fill in Discount Value ";
      } else if (value > 100 && value < 0) {
        this.discountvalueError.status = false;
        this.discountvalueError.message = "Please fill valid Discount Value ";
      } else {
        this.discountvalueError.status = true;
      }
    },
    Expirydate(value) {
      if (!value) {
        this.expirydatevalueError.status = false;
        this.expirydatevalueError.message = "Please fill in Expiry Date ";
      } else {
        this.expirydatevalueError.status = true;
      }
    },
  },

  async mounted() {
    await this.initFn();
  },

  methods: {
    getPackage(val){
      this.selectedPackage = val
    },
    async createCoupon() {
      try {
        const payload = {
          coupon: this.Coupon,
          discount: this.Discountvalue,
          exp_date: this.Expirydate,
          packageName:this.selectedPackage
        };
        const response = await axios.post("/coupon/create", payload);
        if (response.status == 200) {
          this.$bvToast.toast("Success", {
            title: "Coupon created Successfully",
            toaster: "b-toaster-bottom-right",
            variant: "success",
            solid: true,
          });
          this.resetFn();
          this.initFn();
        }
        this.selectedPackage =null
        return response;
      } catch (error) {
      
        console.log("Error",error.response.data.msg)
        this.$bvToast.toast(error.response.data.msg, {
          title: "Coupon",
          toaster: "b-toaster-bottom-right",
          variant: "danger",
          solid: true,
        });

        throw error;
      }
    },
    async initFn(val = undefined) {
      try {
        if (val) this.currentPage = val;
        this.isLoading = true;
        let { data } = await GetAllCoupons({
          limit: this.perPage,
          page: this.currentPage,
        });
        console.log("🚀 ~ file: Coupons.vue:781 ~ initFn ~ data:", data);
        this.totalRows = data.total;
        this.tableItems = data.results.map((x) => {
          return {
            type: {
              id: x._id,
              datecreated: x.created_at,
            },
            expiryDate: x.exp_date.split("T")[0],
            discount: `${x.discount}%`,
            couponCode: x.coupon,
            datecreated: x.created_at.split("T")[0],
            status: x.status,
          };
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },

    resetFn() {
      this.Coupon = null;
      this.Discountvalue = null;
      this.Expirydate = null;
      setTimeout(() => {
        this.couponError.status = null;
        this.discountvalueError.status = null;
        this.expirydatevalueError.status = null;
      }, 100);
    },

    async DeleteCoupon() {
      try {
        this.isLoading = true;
        let payload = {
          id: this.Coupon_ID,
        };
        let { status } = await DeleteCoupons(payload);
        if (status == 200) {
          this.isLoading = false;
          this.$bvToast.toast("Coupon Deleted Successfully", {
            title: "Deleted Successfull",
            toaster: "b-toaster-bottom-right",
            variant: "success",
            solid: true,
          });
          this.initFn();
          this.show = false;
        }
      } catch (error) {
        console.log("Coupon not deleted");
      }
    },

    async StatusDisableFn() {
      let payload = {
        id: this.Admin_ID,
        status: this.status,
      };
      let { data } = await ChangeCouponStatus(payload);
      this.initFn();
      this.showStatusDisable = false;
      this.customersItems = data.map((x) => {
        return {
          Status: x.status,
        };
      });
    },

    async StatusEnableFn() {
      let payload = {
        id: this.Admin_ID,
        status: this.status,
      };
      let { data } = await ChangeCouponStatus(payload);
      this.initFn();
      this.showStatusEnable = false;
      this.customersItems = data.map((x) => {
        return {
          Status: x.status,
        };
      });
    },

    async searchCustomers() {
      try {
        this.tableItems = [];
        this.isLoading = true;
        let { data } = await SearchCoupons({ text: this.searchTable });
        this.tableItems = data.results.map((x) => {
          return {
            type: {
              id: x._id,
              datecreated: x.created_at,
            },
            expiryDate: x.exp_date.split("T")[0],
            discount: `${x.discount}%`,
            couponCode: x.coupon,
            datecreated: x.created_at.split("T")[0],
            status: x.status,
          };
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },

    close() {
      this.searchTable = null;
      this.initFn();
    },

    searchFn() {
      if (this.searchTable && this.searchTable.length > 0) {
        this.searchCustomers();
      } else {
        this.isLoading = false;
        this.initFn();
      }
    },

    sendInfo(id, dltBtn) {
      console.log("ID", id);
      this.Coupon_ID = id;
      this.dltBtn = dltBtn;
      this.show = true;
    },

    statusInfo(id, status, statusBtn) {
      this.Admin_ID = id;
      this.status = status;
      // this.custome_name = cname
      this.statusBtn = statusBtn;
      if (this.status == "Enabled") {
        this.showStatusEnable = true;
      } else {
        this.showStatusDisable = true;
      }
    },

    closePopup() {
      this.show = false;
      this.showStatusDisable = false;
      this.showStatusEnable = false;
    },
    async generateCode() {
      const length = 5;
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;

      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      let { data } = await chkCouponName({ text: result });

      while (data.checkedTest) {
        result = "";
        for (let i = 0; i < length; i++) {
          result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
          );
        }
        ({ data } = await chkCouponName({ text: result }));
      }

      this.code = result;
      this.Coupon = result;
    },
    formatDate(val) {
      if (val) {
        const dates = val.split("-");
        return `${dates[2]}/${dates[1]}/${dates[0]}`;
      }
    },
  },
};
</script>
  
  <style scoped>

  .text-color{
    color: #8793A3;
  }
.table-card-row {
  height: 80vh;
  width: 90vw;
}
/* table card */
.left-container-card {
  background-color: var(--white);
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.1); */
  box-sizing: border-box;
  position: relative;
  /* width: calc(100% - 100px); */
  /* width: 100%; */
  min-height: 80vh;
  padding: 10px;
  left: 0;
  right: 0;

  /* right: 40px; */
  /* bottom: 45px; */
  border: 1px solid rgba(135, 147, 163, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px 0px 0px 16px;
}
.right-container-card {
  background-color: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  position: relative;
  /* width: calc(100% - 100px); */
  width: 100%;
  width: 100%;
  min-height: 80vh;
  padding: 10px;
  left: 0;
  right: 0;

  /* right: 40px; */
  /* bottom: 45px; */
  border: 1px solid rgba(135, 147, 163, 0.25);
  border-radius: 0px 16px 16px 0px;
}
.card-title {
  font-family: "Lato";
  font-weight: 400;
  font-size: 16px;
  color: #333333;
}
.admin-button {
  width: 162px;
  height: 50px;
  background-color: #0b9b7d;
  border-radius: 30px;
}
/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}
/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(33, 150, 83, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--white);
}

.table-container-row::-webkit-scrollbar-thumb {
  background-color: var(--white);
}

.table-container-row .table-element {
 width: 100%;
 min-height: 58vh;
  scrollbar-width: thin;
  
}

/* table element css */
.table-element >>> .table-header {
  background-color: transparent;
  /* align-items: flex-start; */
}

.table-element >>> .table-header th {
  /* color: var(--prime-gray); */
  color: #8793a3;
  font-family: "Lato";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  border-color: transparent;
}

.table-element >>> .table-body td {
  vertical-align: middle;
  border-color: transparent;
}

.sub-text {
  color: #8793a3;
}

.main-text-1 {
  color: #1c72ff;
}

.main-text-2 {
  color: #b22234;
}

.more-icon {
  background: #ffffff;
  border: 1px solid #333333;
  border-radius: 100px;
  padding: 4px;
  width: 35px;
  height: 35px;
}

.more-icon:hover {
  background: #333333;
  color: #ffffff;
}

.check-icon {
  color: #1c72ff;
  width: 14px;
  height: 14px;
  padding-right: 23px;
}

.slash-circle-icon {
  color: #b22234;
  width: 14px;
  height: 14px;
  padding-right: 23px;
}

/* table pagination */
.table-pagination >>> .custom-page-item button,
.table-pagination >>> .custom-page-item span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #333333;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  width: 38px;
  height: 34px;
  padding: 4px;
  border: transparent;
  border-radius: 50%;
}

.pagination-styles {
  position: absolute;
  bottom: 0;
  right: 0;
}

/* table per page selector */
.per-page-select {
  cursor: pointer;
  width: 65px;
}
.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
 
  border-radius: 5px;
  border: 1px solid rgba(135, 147, 163, 0.25);
}

.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 45px;
  border-radius: 4px;
  overflow: hidden;
  border: none;
  /* min-width: 180px; */
}

.table-card-row .search-input-group .search-input::placeholder {
  color: #8793a3;
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {

  .left-container-card {
  background-color: var(--white);
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.1); */
  box-sizing: border-box;
  position: relative;
  /* width: calc(100% - 100px); */
  /* width: 100%; */
  min-height: 40vh;
  padding: 10px;
  left: 0;
  right: 0;

  /* right: 40px; */
  /* bottom: 45px; */
  border: 1px solid rgba(135, 147, 163, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px 0px 0px 16px;
}
.right-container-card {
  background-color: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  position: relative;
  /* width: calc(100% - 100px); */
  width: 100%;
  width: 100%;
  min-height: 60vh;
  padding: 10px;
  left: 0;
  right: 0;

  /* right: 40px; */
  /* bottom: 45px; */
  border: 1px solid rgba(135, 147, 163, 0.25);
  border-radius: 0px 16px 16px 0px;
}

.table-container-row .table-element {
 width: 100%;
 min-height: 40vh;
  scrollbar-width: thin;
  
}
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
 
  .left-container-card {
  background-color: var(--white);
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.1); */
  box-sizing: border-box;
  position: relative;
  /* width: calc(100% - 100px); */
  /* width: 100%; */
  min-height: 40vh;
  padding: 10px;
  left: 0;
  right: 0;

  /* right: 40px; */
  /* bottom: 45px; */
  border: 1px solid rgba(135, 147, 163, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px 16px 0px 0px;
}
.right-container-card {
  background-color: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  position: relative;
  /* width: calc(100% - 100px); */
  width: 100%;
  width: 100%;
  min-height: 60vh;
  padding: 10px;
  left: 0;
  right: 0;

  /* right: 40px; */
  /* bottom: 45px; */
  border: 1px solid rgba(135, 147, 163, 0.25);
  border-radius: 0px 0px 16px 0px;
}
.table-container-row .table-element {
 width: 100%;
 min-height: 40vh;
  scrollbar-width: thin;
  
}
}
</style>
import axios from "axios";

export const GetAllCoupons = async (params) => {
    try {
      return await axios.get("coupon/",{ params });
    } catch (error) {
      return error;
    }
};

export const DeleteCoupons = async (payload) => {
    try {
      return await axios.delete(`/coupon/delete/${payload.id}`);
    } catch (error) {
      return error;
    }
  };

  export const ChangeCouponStatus = (payload) => {
    try{
      return axios.put(`/coupon/statusUpdate/${payload.id}`, payload);
    }catch (error){
      return error;
    }
  };

  export const SearchCoupons = ({ text }) => {
    return axios.get(`/coupon/search`, {
      params: { text },
    });
  };

  export const chkCouponName = ({ text }) => {
    return axios.get(`/coupon/chkStatus`, {
      params: { text },
    });
  };
<template>
  <HomeLayout>
    <b-container class="d-flex flex-column align-items-center justify-content-center px-0">
      <!-- table container row -->
      <b-row class="d-flex flex-row align-items-stretch justify-content-center table-card-row" no-gutters>
        <b-card no-body class="table-container-card">
          <!-- table actions row -->
          <b-row class="d-flex flex-row align-items-center justify-content-between w-100 my-1 my-md-2 px-lg-3 py-3"
            no-gutters>
            <!-- search input column -->
            <b-col class="d-flex flex-column align-items-start justify-content-center px-4 px-lg-0 py-2 py-lg-0">
              <div>
                <b-table ref="questionsTable" :bordered="false" :borderless="true" :hover="false" show-empty :small="true"
                  class="table-element" thead-class="table-header" :busy="isLoading" :per-page="perPage"
                  :fields="customersHeader" :items="customersItems" @row-clicked="onTableRowClicked">
                  <template #cell(Attributes)="data">
                    <b-col class="d-flex flex-column align-items-start justify-content-center px-0">
                      <span class="main-text-Details">{{ data.value }}</span>
                    </b-col>
                  </template>
                  <template #cell(CustomerDetails)="data">
                    <b-col class="d-flex flex-column align-items-start justify-content-center px-0">
                      <span class="main-text-customer">{{ data.value }}</span>
                    </b-col>
                  </template>
                </b-table>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
    </b-container>
  </HomeLayout>
</template>
<script>
import HomeLayout from "@/layout/HomeLayout";

// services
import { GetBusiness } from "@/services/customer_business.service";

export default {
  name: "CustomerDetails",
  components: {
    HomeLayout,
  },
  data() {
    return {
      packageExpireDate:null,
      coupons:null,
      isLoading: false,
      customersHeader: [
        {
          key: "CustomerDetails",
          label: "Customer Details",
        },
        {
          key: "Attributes",
          label: "",
        },
      ],
      customersItems: [],
    };
  },
  async mounted() {
    await this.initFn(this.$route.params.id);
  },
  methods: {
    dateFixer(fxDate) {
    const dateObject = new Date(fxDate);
    const year = dateObject.getFullYear();
    let month = dateObject.getMonth() + 1; // Adding 1 because getMonth() returns a zero-based index
    let day = dateObject.getDate();
    if(day<10) day=`0${day}`
    if(month<10) month=`0${month}`
    const formattedDate = `${day}/${month}/${year}`;
   return formattedDate;
},
    async initFn(val) {

      try {

        let payload = {
          id: val,
        };
        this.isLoading = true;
        console.log("Payload", payload);

        let { data } = await GetBusiness(payload);
        console.log("🚀 ~ file: CustomerDetails.vue:89 ~ initFn ~ data:", data)

        // console.log(data.msg)
        if (data.msg) {
          this.customersItems = [
            {
              CustomerDetails: "Customer name",
              Attributes: this.$route.query.name,
            },
            {
              CustomerDetails: "Customer email address",
              Attributes: this.$route.query.email,
            },
            {
              CustomerDetails: "",
              Attributes: "",
            },
            {
              CustomerDetails: "",
              Attributes: "Registration is not completed yet!",
            },
            // Add more customer items as needed
          ];
          this.isLoading = false;
        }

        else{
        const business = data.customersWithBusiness.busneses;
        const user = data.customersWithBusiness.busnesesOwner.user_id;
        this.coupons = data.customersWithBusiness.couponList
        this.packageExpireDate = data.customersWithBusiness.packageExpireDate
        this.isLoading = false;
        this.customersItems = [
          {
            CustomerDetails: "Customer name",
            Attributes: user.name,
          },
          {
            CustomerDetails: "Customer email address",
            Attributes: user.email,
          },
          {
            CustomerDetails: "Joined date",
            Attributes: this.dateFixer(user.created_at),
          },
          {
            CustomerDetails: "Business name",
            Attributes: business.name,
          },
          {
            CustomerDetails: "IRD/TIN number",
            Attributes: business.ird ,
          },
          {
            CustomerDetails: "BIC code",
            Attributes: business.bic,
          },
          {
            CustomerDetails: "Business is about",
            Attributes: business.type, 
          },
          {
            CustomerDetails: "Type of business",
            Attributes: business.business_type,
          },
          {
            CustomerDetails: "Currently in business",
            Attributes: business.buisness_status,
          },
          {
            CustomerDetails: "Business country",
            Attributes: business.country,
          },
          {
            CustomerDetails: "GST/VAT registration",
            Attributes: business.gst,
          },
          {
            CustomerDetails: "NZBN",
            Attributes: business.nzbn,
          },
          {
            CustomerDetails: "Company address",
            Attributes:
              business.address.number || 'N/A' +
              ", " +
              ( business.address.street || 'N/A') +
              ", " +
              (business.address.province || 'N/A') +
              ", " +
              (business.address.country || 'N/A'),
          },
          {
            CustomerDetails: "Currency",
            Attributes: business.currency ,
          },
          {
            CustomerDetails: "Postal code",
            Attributes: business.address.zipcode ,
          },
          {
            CustomerDetails: "Language",
            Attributes: business.language ,
          },
          {
            CustomerDetails: "Time zone",
            Attributes: business.time_zone ,
          },
          {
            CustomerDetails: "Fiscal year",
            Attributes: business.fiscal_year ,
          },
          {
            CustomerDetails: "No. of customers",
            Attributes: business.customers_range ,
          },
          {
            CustomerDetails: "Online payments",
            Attributes: business.online_payments,
          },
          {
            CustomerDetails: "Current accounts management",
            Attributes: business.current_managing_method,
          },
          {
            CustomerDetails: "Package type",
            Attributes: user.package,
          },
          {
            CustomerDetails: "Package expiry date",
            Attributes: this.$route.query.expiryDate.split("T")[0],
          },
          {
            CustomerDetails: "Used Coupons",
            Attributes: this.coupons,
          },
        ];
        this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    formatDate(val) {
      if (val) {
        const dates = val.split("-");
        return `${dates[2]}/${dates[1]}/${dates[0]}`;
      }
    },
  },
};
</script>
<style scoped>
.table-card-row {
  /* height: 90vh; */
  width: 90vw;
}

.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  /* height: 100vh; */
  margin-bottom: 10px;
  position: relative;
  border-radius: 16px;
  border: 1px solid rgba(135, 147, 163, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.1);
}

/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}

/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

.table-container-row .table-element {
  min-width: 1000px;
}

/* table element css */
.table-element>>>.table-header {
  /* background-color: #f7f7ff; */
  background-color: transparent;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.table-element>>>.table-header th {
  color: black;
  font-size: 16px;
  font-weight: 400;
  border-color: transparent;
}

.table-element>>>.table-body td {
  vertical-align: middle;
}

/* .table-element>>>.table-body tr:nth-child(even) {
  vertical-align: middle;
  background-color: var(--light);
} */

.table-element>>>.main-text-customer {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #828282;
}

.table-element>>>.main-text-details {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
</style>
